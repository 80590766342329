
import { defineComponent } from "vue";
import router from "@/router";
import Upsert from "@/views/course/child/Upsert.vue";

export default defineComponent({
  name: "create-course",
  components: {
    Upsert,
  },
  setup() {
    const onCreateSuccess = () => {
      router.push({ name: "Course" });
    };
    return {
      onCreateSuccess,
    };
  },
});
